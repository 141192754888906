import React, {useEffect} from "react";
import {Box} from "@mui/material";
import NavBar from "../NavBar";

const Header = () => {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isMobile = windowWidth <= 640;

    const textStyles = {
        fontFamily: "SF Pro Display, Arial, sans-serif",
        fontStyle: "normal",
        color: "#E6E6E6",
        // textAlign: "center",
    };

    return (
        <nav className="relative header">
            <div className="absolute top-0 bottom-0 left-0 right-0 pointer-events-none">
                <img alt="header-background" src="/images/header-background.svg"/>
            </div>
            <div className="z-10 relative">
                <NavBar/>

                <Box
                    className={`flex-col items-center flex justify-center w-full ${isMobile ? 'mt-[10px]' : 'mt-[67px]'}`}>
                    <Box className="pt-4 mx-auto text-center">
                        <div
                            className="flex flex-col justify-center items-center lg:w-auto px-4 max-w-screen-lg lg:px-0 sm:ml-[150px] sm:mr-[150px] md:ml-[200px] md:mr-[200px] lg:ml-[250px] lg:mr-[250px]">
                            <p style={{
                                ...textStyles,
                                fontWeight: 600,
                                fontSize: isMobile ? '1.2rem' : '32px',
                                lineHeight: isMobile ? "22.4px" : "44.8px",
                                marginTop: '37px',
                            }}>
                                Next-gen custody, trading and risk infrastructure for institutional CeDeFi and
                                enterprise digital asset operations
                            </p>
                            <p style={{
                                ...textStyles,
                                fontWeight: 400,
                                fontSize: isMobile ? '1.05rem' : '22px',
                                lineHeight: isMobile ? "15.4px" : "30.8px",
                                marginTop: '37px',
                            }}>
                                Supercharge your crypto operations with powerful and secure on-chain automations, direct
                                access to the largest on- and off-chain markets, and enterprise-grade risk mitigation
                                and compliance tools
                            </p>
                        </div>
                        <div
                            className="mt-12 flex header-drop flex items-center justify-center lg:items-start mx-auto">
                            <button
                                style={{
                                    fontFamily: "Inter, Arial, sans-serif",
                                    fontWeight: "600",
                                }}
                                className="bg-secondary w-[200px] h-10 lg:h-[50px]  rounded-lg text-sm lg:text-[16px] font-semibold text-white"
                                onClick={() => window.open('https://calendly.com/contact-primevault/introduction-call', '_blank', 'noopener,noreferrer')}
                            >
                                Book a demo
                            </button>
                            <button
                                className="bg-secondary w-[50px] h-10 lg:h-[50px] rounded-lg text-[16px] font-semibold text-white flex items-center justify-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="22"
                                    viewBox="0 0 23 22"
                                    fill="none"
                                >
                                    <path
                                        d="M8.00834 15.5088L14.6135 9.39558L14.4951 12.4559C14.485 12.7169 14.5707 12.9378 14.735 13.1154C15.0483 13.4539 15.6515 13.5445 16.0598 13.1666C16.2425 12.9975 16.3403 12.7837 16.3499 12.5277L16.5538 7.25616C16.5622 7.03889 16.5481 6.79321 16.316 6.54242C16.0839 6.29164 15.8762 6.25972 15.633 6.25032L10.3512 6.04638C10.0952 6.03614 9.87451 6.11717 9.69183 6.28624C9.28314 6.6645 9.32729 7.27256 9.64059 7.61107C9.80489 7.78859 10.0185 7.89105 10.2795 7.90115L13.3399 8.0195L6.73474 14.1328C6.35494 14.4843 6.33199 15.0778 6.6835 15.4576C7.03501 15.8374 7.62854 15.8604 8.00834 15.5088Z"
                                        fill="white"
                                    />
                                </svg>
                            </button>
                        </div>
                    </Box>
                    <img
                        alt="Mobile-And-WebApp-Dashboard"
                        src="/images/Mobile-And-WebApp-Dashboard.png"
                        className={`${isMobile ? '-mt-[5px]' : '-mt-[60px]'} w-full h-full lg:w-1/2 lg:h-1/2 xl:w-2/3 xl:h-2/3 2xl:w-3/4 2xl:h-3/4 mx-auto`}
                    />
                </Box>
            </div>
        </nav>
    );
};

export default React.memo(Header);

import React from "react";
import {Box} from "@mui/material";
import {Link} from "react-router-dom";

type FooterProps = {
    hideContactUs?: boolean;
}

const Footer: React.FC<FooterProps> = ({hideContactUs = false}) => {
    const currentYear = new Date().getFullYear();
    return (
        <div>
            {!hideContactUs && <footer className="py-12 lg:py-[92px] flex items-center flex-col">
                <p className="lg:text-[45px] text-2xl text-center lg:text-left" style={{
                    fontFamily: 'SF Pro Display, Arial, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '58.05px',
                    color: '#E6E6E6',
                }}>Interested in PrimeVault?</p>
                <p style={{
                    fontFamily: 'PingFang SC, Arial, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '160%',
                    color: '#B7BECB',
                    fontFeatureSettings: "'salt' on, 'liga' off",
                }} className="lg:text-[18px] text-base content mt-6 max-w-[437px]  mx-auto text-center">
                    Leave us a message, and we’ll reach you to explore how we can partner
                    with you.
                </p>
                <button style={{
                    fontFamily: 'Inter, Arial, sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '16px',
                    color: '#FFF',
                }} className="bg-secondary px-4 lg:px-0 lg:w-[200px] h-10 lg:h-[50px]  rounded-lg  mt-10 lg:mt-[55px]"
                    onClick={() => window.open('https://calendly.com/contact-primevault/introduction-call', '_blank', 'noopener,noreferrer')}
                >
                    Contact Us
                </button>
            </footer>}
            <div
                className="py-5 border-t border-border border-opacity-10 mt-[60px] mx-4 lg:mx-[100px] flex justify-between items-center">
                <Box className='space-y-2'>
                    <p style={{
                        fontFamily: 'PingFang SC, Arial, sans-serif',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '16px',
                        color: '#E6E6E6',
                    }}>
                        &copy; {currentYear} PrimeVault<sup>TM</sup>. All Rights Reserved.
                    </p>
                    <p style={{
                        fontFamily: 'PingFang SC, Arial, sans-serif',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 300,
                        lineHeight: '16px',
                        color: '#E6E6E6',
                    }}><Link to="/privacy-policy" style={{color: '#E6E6E6', textDecoration: 'none'}}>Privacy
                        Policy</Link></p>
                </Box>
                <div className="flex items-center space-x-3">
                    <img alt="linkedin" src="/images/linkedin.png" style={{height: '30.51px', cursor: 'pointer'}}
                         onClick={() => window.location.href = 'https://www.linkedin.com/company/primevault'}/>
                    <img alt="twitter" src="/images/x.png" style={{height: '30.51px', cursor: 'pointer'}}
                         onClick={() => window.location.href = 'https://x.com/primevaultHQ'}/>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Footer);

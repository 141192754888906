import React, { useEffect } from 'react';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const PrivacyPolicy: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const commonTextStyle = {
        fontFamily: "PingFang SC, Arial, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "160%",
        color: "#B7BECB",
        fontFeatureSettings: "'salt' on, 'liga' off",
    };

    const headingStyle = {
        fontFamily: "PingFang SC, Arial, sans-serif",
        fontStyle: "normal",
        fontWeight: 800,
        lineHeight: "160%",
        color: "#E6E6E6",
        fontFeatureSettings: "'salt' on, 'liga' off",
    };

    const listStyle = {
        marginLeft: '20px',
        listStyle: 'disc',
        color: '#B7BECB',
    };

    const listItemStyle = {
        padding: '2px 16px',
        color: '#B7BECB',
        display: 'list-item',
    };

    return (
        <div>
            <nav className="relative header">
                <div className="absolute top-0 bottom-0 left-0 right-0 pointer-events-none">
                    <img alt="header-background" src="/images/header-background.svg"/>
                </div>
                <div className="z-10 relative">
                    <NavBar/>
                </div>
                <div className="p-4 max-w-screen-lg mx-auto">
                    <p className="text-[24px] font-bold mb-2" style={{
                        fontFamily: "SF Pro Display, Arial, sans-serif",
                        fontStyle: "normal",
                        fontWeight: 800,
                        lineHeight: "129%",
                        color: "#E6E6E6",
                    }}>PrimeVault Privacy Policy</p>

                    <p className="mb-4 text-[14px]" style={commonTextStyle}>
                        Last Updated: January 2025
                    </p>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={headingStyle}>
                            Introduction
                        </h2>
                        <p className="text-[16px]" style={commonTextStyle}>
                            PrimeVault is a Delaware, USA-registered company headquartered in San Francisco, CA. We provide institutional and enterprise customers with end-to-end solutions for digital asset operations, including <strong>custody, role-based security settings, trading access, risk management, and compliance.</strong>
                        </p>
                        <br/>
                        <p className="text-[16px]" style={commonTextStyle}>
                            We are committed to protecting your privacy and securing your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your data when you use our <strong>crypto and DeFi trading, custody, and risk management platform</strong> ("Platform").
                        </p>
                        <br/>
                        <p className="text-[16px]" style={commonTextStyle}>
                            We recognize that privacy is an ongoing responsibility and will update this policy as needed. <strong>By using our Platform, you consent to the practices described in this Privacy Policy.</strong>
                        </p>
                    </section>
                    <hr className="border-[#2A2D36] my-6"/>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={headingStyle}>
                            Data We Collect
                        </h2>
                        <p className="text-[16px]" style={commonTextStyle}>
                            PrimeVault collects both <strong>personal</strong> and <strong>non-personal information</strong> to enhance user experience and ensure compliance with regulations.
                        </p>

                        <h3 className="text-lg font-semibold mt-4 mb-2" style={headingStyle}>
                            Personal Information:
                        </h3>
                        <ul style={listStyle}>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Contact Information:</strong> Name, job title, employer name, work email, phone number, and work address.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Identification Information:</strong> Government-issued ID (passport, driver's license) for identity verification.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Account Information:</strong> Username, Organization ID, and other account-related details.
                                </span>
                            </li>
                        </ul>

                        <h3 className="text-lg font-semibold mt-4 mb-2" style={headingStyle}>
                            Financial Information:
                        </h3>
                        <ul style={listStyle}>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Cryptocurrency Wallet Addresses:</strong> To facilitate transactions and account management.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Transaction Data:</strong> Details of transactions on the Platform.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Payment Information:</strong> Credit card, bank account, or other payment details.
                                </span>
                            </li>
                        </ul>

                        <h3 className="text-lg font-semibold mt-4 mb-2" style={headingStyle}>
                            Technical Information:
                        </h3>
                        <ul style={listStyle}>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Device Information:</strong> Device type, OS, browser type, and IP address.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Usage Information:</strong> Interactions with the Platform, including page views, clicks, and session duration.
                                </span>
                            </li>
                        </ul>

                        <p className="text-[16px] mt-4" style={commonTextStyle}>
                            We may also collect additional <strong>employment or industry-related information</strong> from third parties such as LinkedIn.
                        </p>
                    </section>
                    <hr className="border-[#2A2D36] my-6"/>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={headingStyle}>
                            Legal Basis for Processing Personal Data
                        </h2>
                        <p className="text-[16px]" style={commonTextStyle}>
                            PrimeVault processes personal data under the <strong>General Data Protection Regulation (GDPR) and Lei Geral de Proteção de Dados (LGPD)</strong> based on:
                        </p>
                        <ul style={listStyle}>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Contract Performance:</strong> To fulfill our obligations when providing services.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Legal Obligation:</strong> To comply with applicable laws and regulations.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Legitimate Interest:</strong> To improve services, prevent fraud, and personalize user experience.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Consent:</strong> For marketing or analytics, where required. You can withdraw consent anytime via preference settings.
                                </span>
                            </li>
                        </ul>
                    </section>
                    <hr className="border-[#2A2D36] my-6"/>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={headingStyle}>
                            How We Use Your Information
                        </h2>
                        <p className="text-[16px]" style={commonTextStyle}>
                            We use collected information to:
                        </p>
                        <ul style={listStyle}>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    Provide, improve, and personalize our services.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    Verify identity and comply with regulations.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    Process transactions and manage accounts.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    Communicate with users about their accounts, transactions, and updates.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    Analyze user behavior to enhance Platform functionality.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    Detect and prevent fraud and security breaches.
                                </span>
                            </li>
                        </ul>
                        <p className="text-[16px] mt-4" style={commonTextStyle}>
                            We <strong>do not sell personal information</strong> and only share data with third parties involved in service delivery.
                        </p>
                    </section>
                    <hr className="border-[#2A2D36] my-6"/>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={headingStyle}>
                            Use of the PrimeVault Website
                        </h2>
                        <p className="text-[16px]" style={commonTextStyle}>
                            Like most websites, PrimeVault automatically collects data such as <strong>IP addresses, browser type, location, and page interactions</strong> to optimize the user experience. This helps:
                        </p>
                        <ul style={listStyle}>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    Diagnose server issues.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    Analyze trends and user behavior.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    Enhance site functionality.
                                </span>
                            </li>
                        </ul>
                        <p className="text-[16px] mt-4" style={commonTextStyle}>
                            We have a <strong>legitimate interest</strong> in understanding customer usage to provide relevant services and staffing.
                        </p>
                    </section>
                    <hr className="border-[#2A2D36] my-6"/>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={headingStyle}>
                            Sharing of Personal Information
                        </h2>
                        <p className="text-[16px]" style={commonTextStyle}>
                            PrimeVault stores personal data in <strong>U.S.-based third-party cloud databases</strong> for <strong>storage and retrieval only.</strong>
                        </p>
                        <h3 className="text-lg font-semibold mt-4 mb-2" style={headingStyle}>
                            Limited Data Disclosure
                        </h3>
                        <p className="text-[16px]" style={commonTextStyle}>
                            We do <strong>not</strong> share personal data unless:
                        </p>
                        <ul style={listStyle}>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>With Your Consent:</strong> If explicitly requested.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>PrimeVault Events:</strong> When attending our hosted or co-sponsored events.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Legal Compliance:</strong> To comply with laws, subpoenas, court orders, or enforce agreements.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Service Providers:</strong> With third-party vendors supporting our services.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Emergency Situations:</strong> To address safety threats or force majeure events.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Disputes & Claims:</strong> In legal disputes or cases requiring verification of legal authority.
                                </span>
                            </li>
                        </ul>
                        <p className="text-[16px] mt-4" style={commonTextStyle}>
                            PrimeVault may also share <strong>aggregated, non-personally identifiable data with partners, service providers, advertisers, and third parties for marketing and analytics</strong>.
                        </p>
                    </section>
                    <hr className="border-[#2A2D36] my-6"/>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={headingStyle}>
                            Third-Party Services & Social Media
                        </h2>
                        <p className="text-[16px]" style={commonTextStyle}>
                            If you engage with <strong>Facebook, LinkedIn, or Twitter</strong> via our site, their privacy policies apply. These platforms <strong>may link your visit to your profile</strong>. We encourage reviewing their policies before engaging.
                        </p>
                    </section>
                    <hr className="border-[#2A2D36] my-6"/>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={headingStyle}>
                            Your CCPA Rights: Do Not Sell My Personal Information
                        </h2>
                        <p className="text-[16px]" style={commonTextStyle}>
                            Under the <strong>California Consumer Privacy Act (CCPA)</strong>, California residents can <strong>opt out of the sale of their personal information</strong>.
                        </p>
                        <p className="text-[16px]" style={commonTextStyle}>
                            <strong>PrimeVault does not sell your personal data</strong>, but you can request that we <strong>do not share your data for targeted advertising</strong> by emailing <a href="mailto:privacy@primevault.com" className="text-blue-400 hover:text-blue-300">privacy@primevault.com</a>.
                        </p>
                    </section>
                    <hr className="border-[#2A2D36] my-6"/>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={headingStyle}>
                            Transferring Personal Data to the U.S.
                        </h2>
                        <p className="text-[16px]" style={commonTextStyle}>
                            PrimeVault operates globally, meaning your data may be <strong>processed and stored in the U.S.</strong> or other countries.
                        </p>
                        <p className="text-[16px]" style={commonTextStyle}>
                            To comply with <strong>GDPR and LGPD</strong>, we implement:
                        </p>
                        <ul style={listStyle}>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Standard Contractual Clauses (SCCs)</strong> approved by the European Commission.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Adequacy Decisions</strong> where applicable.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Other legally recognized safeguards</strong> to protect your data.
                                </span>
                            </li>
                        </ul>
                        <p className="text-[16px] mt-4" style={commonTextStyle}>
                            Since our founding, PrimeVault has received <strong>zero government requests for information</strong>. For inquiries, email <a href="mailto:privacy@primevault.com" className="text-blue-400 hover:text-blue-300">privacy@primevault.com</a>.
                        </p>
                    </section>
                    <hr className="border-[#2A2D36] my-6"/>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={headingStyle}>
                            Your Rights and How to Exercise Them
                        </h2>
                        <p className="text-[16px]" style={commonTextStyle}>
                            Depending on your location, your rights include:
                        </p>
                        <ul style={listStyle}>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Access & Portability:</strong> Request a copy of your data, including processing purposes and retention details.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Correction:</strong> Request rectification of inaccurate or incomplete data.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Erasure (Right to be Forgotten):</strong> Request deletion of your data, subject to legal limitations.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Opt-Out of Data Sharing/Sale:</strong> California residents can opt out under CCPA.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Restrict or Object to Processing:</strong> Limit or object to data use, including direct marketing.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Withdraw Consent:</strong> Withdraw consent for non-essential data processing at any time.
                                </span>
                            </li>
                        </ul>

                        <h3 className="text-lg font-semibold mt-4 mb-2" style={headingStyle}>
                            How to Submit a Request
                        </h3>
                        <p className="text-[16px]" style={commonTextStyle}>
                            Send an email to <a href="mailto:privacy@primevault.com" className="text-blue-400 hover:text-blue-300">privacy@primevault.com</a> with the subject <strong>"Privacy Rights Request."</strong> We will verify your identity and respond within the legally required timeframe.
                        </p>

                        <h3 className="text-lg font-semibold mt-4 mb-2" style={headingStyle}>
                            Access to Your Personal Data
                        </h3>
                        <ul style={listStyle}>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    We provide reasonable <strong>free access</strong> to your data.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    If delayed, we will notify you of a completion date.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    If access is denied, we will explain the reason and <strong>provide guidance on contesting the decision.</strong>
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    In cases of <strong>disproportionate effort or third-party privacy concerns</strong>, we may <strong>limit access or charge a reasonable administrative fee.</strong>
                                </span>
                            </li>
                        </ul>
                        <p className="text-[16px] mt-4" style={commonTextStyle}>
                            If technically feasible, we will provide your <strong>data in a portable format</strong> upon request.
                        </p>
                        <p className="text-[16px]" style={commonTextStyle}>
                            If you have concerns, you may <strong>lodge a complaint with the relevant data protection authority.</strong>
                        </p>
                    </section>
                    <hr className="border-[#2A2D36] my-6"/>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={headingStyle}>
                            Data Storage and Retention
                        </h2>
                        <p className="text-[16px]" style={commonTextStyle}>
                            PrimeVault retains personal data:
                        </p>
                        <ul style={listStyle}>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>For the duration of the customer relationship</strong> and a period thereafter for operational and historical analysis.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Prospect data</strong> is retained until it no longer has business value and is purged.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    <strong>Personal data can be deleted upon verified request.</strong>
                                </span>
                            </li>
                        </ul>
                        <p className="text-[16px] mt-4" style={commonTextStyle}>
                            For specific retention inquiries, email <a href="mailto:privacy@primevault.com" className="text-blue-400 hover:text-blue-300">privacy@primevault.com</a>.
                        </p>
                    </section>
                    <hr className="border-[#2A2D36] my-6"/>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={headingStyle}>
                            Children's Data
                        </h2>
                        <p className="text-[16px]" style={commonTextStyle}>
                            Our Platform <strong>is not intended for users under 18.</strong>
                        </p>
                        <ul style={listStyle}>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    We do not knowingly collect data from minors.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    If we learn of unintentional collection, we will <strong>delete the data immediately.</strong>
                                </span>
                            </li>
                        </ul>
                    </section>
                    <hr className="border-[#2A2D36] my-6"/>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={headingStyle}>
                            Changes to This Privacy Policy
                        </h2>
                        <p className="text-[16px]" style={commonTextStyle}>
                            We may <strong>update this Privacy Policy periodically.</strong>
                        </p>
                        <ul style={listStyle}>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    Material changes will be communicated via email or website notice.
                                </span>
                            </li>
                            <li style={listItemStyle}>
                                <span style={{ color: '#B7BECB' }}>
                                    We encourage you to review this page for the latest updates.
                                </span>
                            </li>
                        </ul>
                    </section>
                    <hr className="border-[#2A2D36] my-6"/>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={headingStyle}>
                            Contact Us
                        </h2>
                        <p className="text-[16px]" style={commonTextStyle}>
                            For privacy-related questions or suggestions, contact our <strong>Data Protection Office</strong> at <a href="mailto:privacy@primevault.com" className="text-blue-400 hover:text-blue-300">privacy@primevault.com</a>.
                        </p>
                    </section>
                </div>
            </nav>
            <Footer/>
        </div>
    );
}

export default PrivacyPolicy;
